import React from "react";

import { readState } from "@/__main__/app-state.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function Teams() {
  const {
    lol: { proBuildTeams = [] },
  } = useSnapshot(readState);

  const _regions = proBuildTeams.reduce((acc, curr) => {
    const { region } = curr;
    if (!acc[region]) acc[region] = [];
    acc[region].push(curr);
    return acc;
  }, {});

  return <div />;
}

export default Teams;
